import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import { useAuthentication } from './useAuthentication';
import icon01d from '../icons/weather/01d.png';
import icon02d from '../icons/weather/02d.png';
import icon03d from '../icons/weather/03d.png';
import icon04d from '../icons/weather/04d.png';
import icon09d from '../icons/weather/09d.png';
import icon10d from '../icons/weather/10d.png';
import icon11d from '../icons/weather/11d.png';
import icon13d from '../icons/weather/13d.png';
import icon50d from '../icons/weather/50d.png';

const REFETCH_INTERVAL = 5 * 60_000;
const EMPTY_ARRAY = [];
const ICONS = {
  '01d': icon01d,
  '02d': icon02d,
  '03d': icon03d,
  '04d': icon04d,
  '09d': icon09d,
  '10d': icon10d,
  '11d': icon11d,
  '13d': icon13d,
  '50d': icon50d,
};

function getIcon(key) {
  // replace n (night) with d (day) to keep it simpler
  const inlineIcon = ICONS[key.replace('n', 'd')];
  if (inlineIcon) {
    return inlineIcon;
  }
  console.warn('NEW WEATHER ICON!? ' + JSON.stringify(key));
  return `//openweathermap.org/img/w/${key}.png`;
}

const Context = React.createContext(null);

export function ApiDataProvider({ children }) {
  return <Context.Provider value={useBffProvider()}>{children}</Context.Provider>;
}

const TEST_DATA = [
  {
    topic: 'Hub events',
    day: '2024-12-13 17:00',
    what: 'tretton37 🎄 Xmas Afterwork 🤶🎅💫',
  },
  {
    topic: 'Hub events',
    day: '2024-12-19 12:00',
    what: 'tretton37 Local Monthly 🇸🇮',
  },
  {
    topic: 'Birthdays',
    day: '2025-12-23 07:00',
    what: 'Matic 🎂🏋',
  },
  {
    topic: 'Birthdays',
    day: '2025-12-25 07:00',
    what: 'Nera 🎂🫐',
  },
  {
    topic: 'Birthdays',
    day: '2025-01-02 07:00',
    what: 'Gregor 🎂⚔️',
  },
];

function toAnnounceItem(suffix = '🇸🇪') {
  return item => ({
    topic: 'Public Holidays',
    day: item.Date || item.date,
    what: (item.Name || item.name) + ' ' + suffix,
  });
}

export function usePreannounce() {
  const device = useAuthentication().state.authentication.device;
  const { city } = device;
  const { preannounce, holidaysSI = [], holidaysSE = [] } = React.useContext(Context);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => {
    const all = holidaysSE.map(toAnnounceItem('🇸🇪')).concat(holidaysSI.map(toAnnounceItem('🇸🇮')));
    const list = all.filter(item => {
      const days = moment(item.day).diff(moment(), 'days');
      return days < 60 && days > -3;
    });
    const preannounceWithFallback = preannounce || (city === 'Ljubljana' && TEST_DATA) || [];
    for (const item of preannounceWithFallback) {
      const days = moment(item.day).diff(moment(), 'days');
      const hours = moment(item.day).diff(moment(), 'hours');
      if (hours > 1 && days < 13) list.push(item);
    }
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, JSON.stringify(holidaysSI), JSON.stringify(holidaysSE), JSON.stringify(preannounce)]);
}

export function useWeather() {
  const { weather } = React.useContext(Context);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => weather, [JSON.stringify(weather)]);
}

export function useEmployeesInOffice() {
  const { socialize } = React.useContext(Context);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => socialize, [JSON.stringify(socialize)]);
}

export function useRssPosts() {
  const { rssPosts } = React.useContext(Context);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => rssPosts, [JSON.stringify(rssPosts)]);
}

function formatDate(date) {
  return moment(date).tz('Europe/Berlin').calendar(null, {
    sameDay: '[Today at] H:mm',
    nextDay: '[Tomorrow] H:mm',
    lastDay: '[Yesterday at] H:mm',
    nextWeek: 'Do MMMM',
    lastWeek: 'Do MMMM',
    sameElse: 'Do MMMM',
  });
}

function formatRssPosts(posts, rssFeeds) {
  if (!posts?.length) return [];
  let formatted = [];
  for (const i in rssFeeds) {
    if (!posts[i]) continue;
    formatted = formatted.concat(
      posts[i].map(item => ({
        ...item,
        type: 'rssItem',
        author: {
          name: rssFeeds[i].title,
          avatarUrl: rssFeeds[i].imageUrl,
        },
        date: formatDate(item.date),
      }))
    );
  }
  return formatted;
}

function useBffProvider() {
  const device = useAuthentication().state.authentication.device;
  const { city, id: deviceId, officeId, rssFeeds } = device;
  const [weather, setWeather] = React.useState({
    min: undefined,
    max: undefined,
    temp: undefined,
    city: '',
    weather: '',
    icon: '',
  });
  const [refetchError, setRefetchError] = React.useState(null);
  const [socialize, setSocialize] = React.useState([]);
  const [rssPosts, setRssPosts] = React.useState([]);
  const [preannounce, setPreannounce] = React.useState([]);
  const [holidaysSE, setHolidaysSE] = React.useState([]);
  const [holidaysSI, setHolidaysSI] = React.useState([]);

  const feeds = rssFeeds.map(({ url }) => `&rss=${encodeURIComponent(url)}`).join('');
  const bffUrl = city && `https://api.1337co.de/bff/tv/${city}?device_id=${deviceId}&office_id=${officeId}${feeds}`;

  const mounted = React.useRef(true);
  React.useEffect(() => () => (mounted.current = false), []);
  React.useEffect(() => {
    async function refetchBffData() {
      try {
        const response = await fetch(bffUrl);
        const body = await response.text();
        const json = JSON.parse(body);
        const weather = json?.weather || {};
        if (mounted) {
          setRssPosts(formatRssPosts(json.feeds, rssFeeds));
          setSocialize(json?.socialize || EMPTY_ARRAY);
          setWeather({ ...weather, iconSrc: getIcon(weather.icon) });
          setPreannounce(json?.preannounce);
          setHolidaysSE(json?.holidaysSE || []);
          setHolidaysSI(json?.holidaysSI || []);
        }
      } catch (error) {
        if (mounted) {
          console.error('refetchBffData', error);
          setRefetchError(error);
        }
      }
    }

    let interval;
    if (bffUrl) {
      refetchBffData();
      interval = setInterval(refetchBffData, REFETCH_INTERVAL);
    }
    return () => interval && clearInterval(interval);
  }, [bffUrl, rssFeeds]);

  return { refetchError, holidaysSE, holidaysSI, preannounce, rssPosts, socialize, weather };
}
